import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';

@Component({
  selector: 'inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {


  public gridData = {
    data: [],
    per_page: 1,
    current_page: 1,
    total: 0,
  };
  public gridConfig = {
    column: {},
  };



  public filterFormData = new FilterPoJo();
  public formData = new PoJo();
  constructor(public GS: GlobalService) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this.GS.Ajax(this.GS.WIU.listInbox, this.filterFormData).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.gridConfig = results.data.config;
      this.gridData = results.data.data;
    });
  }

  onPageChange(event?) {
    console.log('>>>>onPageChange()<<<<<<<', event);
    this.filterFormData.page = event;
    this.list();
  }

  search() {
    this.filterFormData.page = 1;
    this.list();
  }

  getInbox(uid) {
    let data = { uid: uid };
    this.GS.Ajax(this.GS.WIU.getInbox, data).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.formData = results.data;
      this.GS.show('message');
    });
  }

  getMessageClose() {
    this.list();
    this.GS.hide('message');
  }

}

export class PoJo {
  uid: number;
  subject: string;
  body: string;
  created_at: string;
}

export class FilterPoJo {
  per_page: number = 15;
  page: number = 1;
}