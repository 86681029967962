import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { WIU } from './wiu';
import { MSGS } from './msgs';
import { CONST } from './const';
import { PLACEHOLDER } from './placeholder';

declare var $: any;

@Injectable()
export class GlobalService {
    public COMMON_URL = '';
    public API = '';
    public WIU = WIU;
    public PH = PLACEHOLDER;
    public DDKEY = {
        TEXT: 'name',
        VALUE: 'id'
    };
    public DD_DEF = -1;
    public CONST = CONST;
    public MSGS = MSGS;
    public user_detail = new UserDetail();
    public loaderCounter = 0;
    public environment = environment;
    public perPageDD = [10, 25, 50, 100];
    public gridData = {
        data: [],
        per_page: 1,
        current_page: 1,
        total: 0,
    };
    public gridConfig = {
        column: {},
    };
    public app_config = {
        currency_code: 'INR',
        winning_time: 10,
        refer_and_earn: null,
        base_ref_link: null,
        telegram: "@lucky91world",
        print_company_bank: false,
        IS_DEMO_ENV: true,
        IS_DEMO_LIVE: false,
        visible_quiz_timer: false,
        enable_spin: false,
        referral_bonus: 10,
        live_message: '',
    };
    public notifications = [];
    http: any;
    public original_channel = 'W';// W for www web, M www mobile, G for googleapp mobile, B for googleapp web, L for local
    constructor(private httpClient: HttpClient,
        private router: Router,
        private toastrService: ToastrService
    ) {
        if (environment.channel == 'W') {
            let url = window.location.href;
            let api_split = url.split("/");
            if (api_split[2] == 'localhost:4200') {
                this.API = environment.LOCAL_API;
            } else {
                // this.API = environment.protocol + '://' + environment.domain + '/' + environment.API;
                let bUrl = api_split[2];
                let sub_domain = bUrl.split(".");
                switch (sub_domain[0]) {
                    case 'www':
                        this.original_channel = 'W';
                        break;
                    case 'googleapp':
                        this.original_channel = 'B';
                        break;
                    default:
                        this.original_channel = 'w';
                        break;
                }
                this.COMMON_URL = api_split[0] + '//' + bUrl;
                if (environment.API == '')
                    this.API = this.COMMON_URL;
                else
                    this.API = this.COMMON_URL + '/' + environment.API;
            }
            return;
        }
        let sub_domain = environment.domain.split(".");
        switch (sub_domain[0]) {
            case 'www':
                this.original_channel = 'M';
                break;
            case 'googleapp':
                this.original_channel = 'G';
                break;
            default:
                this.original_channel = 'm';
                break;
        }
        if (environment.API == '')
            this.API = environment.protocol + '://' + environment.domain;
        else
            this.API = environment.protocol + '://' + environment.domain + '/' + environment.API;
    }

    public getAppConfig() {
        this.Ajax(this.WIU.getAppConfig).subscribe((results: any) => {
            if (results.status != this.CONST.SUCCESS) {
                this.alert('danger', results);
                return;
            }
            this.app_config = results.data;
        });
    }

    public sessionExist() {
        if (localStorage.getItem(this.CONST.TOKEN_KEY))
            return true;
        return false;
    }

    public showLiveMessage(display = false) {
        if (!this.app_config.IS_DEMO_ENV) {
            return;
        }
        if (this.app_config.IS_DEMO_ENV && !this.app_config.IS_DEMO_LIVE) {
            return;
        }
        if (!display) {
            let last_refer = parseInt(localStorage.getItem('live_message'));
            if (last_refer) {
                let now_date = Date.now();
                let diff = now_date - last_refer;
                diff = Math.floor(diff / 1000 / 60);
                if (diff >= 60) {
                    this.show('show_live_message');
                    localStorage.setItem('live_message', Date.now().toString());
                }
            } else {
                this.show('show_live_message');
                localStorage.setItem('live_message', Date.now().toString());
            }
            return;
        }
        this.show('show_live_message');
        localStorage.setItem('live_message', Date.now().toString());
    }

    public refer_and_earn_link = '';
    public base_ref_link = 'Play Online lottery, Just register and start playing free, https://lucky91world.com/#/auth/refer';

    public displayReferLink(display = false) {
        if (this.app_config.IS_DEMO_ENV) {
            return;
        }
        if (!this.user_detail.mobile)
            return;
        if (this.app_config.refer_and_earn)
            this.PH.refer_and_earn = this.app_config.refer_and_earn;
        if (this.app_config.base_ref_link)
            this.base_ref_link = this.app_config.base_ref_link;
        this.refer_and_earn_link = this.base_ref_link + '/' + this.user_detail.mobile;
        console.log('this.refer_and_earn_link', this.refer_and_earn_link);
        if (!display) {
            let last_refer = parseInt(localStorage.getItem(this.CONST.REFER_KEY));
            if (last_refer) {
                let now_date = Date.now();
                let diff = now_date - last_refer;
                diff = Math.floor(diff / 1000 / 60);
                if (diff >= 60) {
                    this.show('refer_and_earn');
                    localStorage.setItem(this.CONST.REFER_KEY, Date.now().toString());
                }
            } else {
                this.show('refer_and_earn');
                localStorage.setItem(this.CONST.REFER_KEY, Date.now().toString());
            }
            return;
        }
        this.show('refer_and_earn');
        localStorage.setItem(this.CONST.REFER_KEY, Date.now().toString());
    }

    public showHideLoader(show = false) {
        if (show)
            document.getElementById('ajaxloader').classList.remove('hide');
        else
            document.getElementById('ajaxloader').classList.add('hide');
    }

    public parseMe(num) {
        return parseFloat(num);
    }

    public isNaN(num) {
        if (num === null || num === undefined)
            return true;
        return isNaN(num.toString());
    }

    public show(modal) {
        // $("#" + modal).modal();
        document.getElementById(modal).classList.add('show');
        document.getElementById(modal).setAttribute("Style", "display: block");
    }

    public hide(modal) {
        // $("#" + modal).modal('hide');
        document.getElementById(modal).classList.remove('show');
        document.getElementById(modal).setAttribute("Style", "display: none");
    }

    public getUserDetail() {
        if (!this.sessionExist())
            return;
        this.Ajax(this.WIU.getUserDetail).subscribe((results: any) => {
            if (results.status != this.CONST.SUCCESS) {
                this.user_detail = new UserDetail();
                return;
            }
            this.user_detail = results.data;
        });
    }

    public alert(type, data, toast_val_err = false, title = '') {
        // console.log('data', this.isJson(data), data);
        title = ((title == '') ? (type == 'success' ? this.PH.message : this.PH.alert) : this.PH.alert);
        let msg = '';
        if (this.isJson(data)) {
            if (data.code == CONST.VAL_ERR && !toast_val_err)
                return;
            if (Array.isArray(data.messages))
                msg = data.messages[0];
            else
                msg = data.messages[Object.keys(data.messages)[0]];
            if (data.data) {
                msg = msg + ' ';
                if (Array.isArray(data.data)) {
                    for (let i = 0; i < data.data.length; i++)
                        msg = msg + ' ' + data.data[i] + ',';
                } else
                    msg = msg + ' ' + data.data
            }
        }
        else
            msg = data;
        this.showToast(type, title, msg);
        if (this.isJson(data) && data.status == this.CONST.ERROR) {
            if (data.code == 'restricted_ip' || data.code == 'token_expired' || data.code == 'logged_in_from_other_system') {
                localStorage.removeItem(this.CONST.TOKEN_KEY);
                sessionStorage.removeItem(this.CONST.TOKEN_KEY);
                this.router.navigate(['/auth/signin']);
                return;
            }
            if (data.code == 'unauthorized_access') {
                let url = window.location.href;
                let url_split = url.split("/");
                url_split = url_split[2].split('.');
                document.location.href = this.environment.protocol + '://' + this.environment.domain + '/#/';
                return;
            }
        }
    }

    private showToast(type: string, title: string = '', body: string) {
        console.log('showToast', type, title, body);
        if (type == 'success')
            this.toastrService.success(body, title);
        if (type == 'danger')
            this.toastrService.error(body, title);
        if (type == 'warning')
            this.toastrService.warning(body, title);
        if (type == 'info')
            this.toastrService.info(body, title);
    }


    public isLoggedIn() {
        this.Ajax(this.WIU.isLoggedIn).subscribe((results: any) => {
            if (results.code == 'unauthorized_access') {
                this.alert('danger', results);
                return;
            }
            if (results.status == this.CONST.SUCCESS) {
                if (!results.data.passcode_checked) {
                    this.navigate('/auth/otp');
                } else
                    this.navigate('/');
            } else {
                this.navigate('/auth/signin');
            }
        }, error => {
        });
    }

    public comingSoon(body: string = 'Coming Soon!') {
        this.showToast('info', 'Information', body);
    }

    public navigate(url, params = {}) {
        this.router.navigate([url], params);
    }

    public isJson(str) {
        return ((str != null && typeof str == 'object') ? true : false);
    }


    public Ajax(url, data?, requestType?, paramType?, paramData?, headers?: HttpHeaders) {
        if (!data) {
            data = {};
        }
        if (!requestType) {
            requestType = this.CONST.POST;
        }
        if (!headers) {
            headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        }
        if (!paramType) {
            paramType = this.CONST.QUERY;
        }
        if (paramType == this.CONST.TEMPLATE) {
            url = this.createTemplateParam(url, paramData);
        }

        if (requestType == this.CONST.GET) {
            return this
                .httpClient
                .get(this.API + '/' + url, { headers });
        }
        if (requestType == this.CONST.POST) {
            return this.httpClient.post(this.API + '/' + url, JSON.stringify(data), { headers });
        }
    }

    private createTemplateParam(url, data) {
        for (let i = 0; i < data.length; i++)
            url = url + '/' + data[i];
        return url;
    }

    public formatDate(date_str = null) {
        let date = new Date();
        if (date_str)
            date = new Date(date_str);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let today = '' + yyyy;
        if (mm < 10)
            today = today + '-0' + mm;
        else
            today = today + '-' + mm;
        if (dd < 10)
            today = today + '-0' + dd;
        else
            today = today + '-' + dd;
        return today;
    }

    public goToDashboard() {
        this.navigate('/');
    }

    public accessibleMenu = {};

    public getMenu() {
        this.Ajax(this.WIU.getMenu).subscribe((results: any) => {
            if (results.status != this.CONST.SUCCESS) {
                this.alert('danger', results);
                return;
            }
            this.accessibleMenu = results.data;
        });
    }

    public logout() {
        this.Ajax(this.WIU.logout).subscribe((results: any) => {
            if (results.status != this.CONST.SUCCESS) {
                this.alert('danger', results);
                return;
            }
            localStorage.removeItem(this.CONST.TOKEN_KEY);
            sessionStorage.removeItem(this.CONST.TOKEN_KEY);
            this.user_detail = new UserDetail();
            this.alert('success', results);
            this.getMenu();
            setTimeout(() => {
                this.navigate('/auth/signin');
            }, 1000);
        });
    }

    public listLangMessage() {
        this.Ajax(this.WIU.listLangMessage).subscribe((results: any) => {
            if (results.status != this.CONST.SUCCESS) {
                this.alert('danger', results);
                return;
            }
            this.PH = results.data;
        });
    }

    public ucFirst(string: string = '') {
        if (string.length > 1)
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        else
            return string.charAt(0).toUpperCase();
    }

    public strToUpper(string: string = '') {
        return string.toUpperCase();
    }

    public toDec(num) {
        return parseFloat(num.toFixed(2));
    }

    public getDDReq(query, model_val, is_multi = false) {
        console.log('>>>>>>>>>query<<<<<<<<', query);
        let data = {};
        if (query != undefined && query != this.DD_DEF) {
            data['query'] = query;
        } else if (model_val && !is_multi) {
            data['id'] = model_val;
        }
        if (is_multi && model_val && Array.isArray(model_val) && model_val.length) {
            data['ids'] = model_val;
        }
        console.log('getDDRequestData', data, model_val);
        return data;
    }

    public getFormError(results) {
        if (results.code == CONST.VAL_ERR) {
            return results.messages;
        }
        return {};
    }

    public sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
}


export class DataSourceClass {
    ev: string;
    value: any
}

export class UserDetail {
    name: string;
    company_bank: any = { 'upi': '' };
    user_bank: Array<string>;
    max_deposit_limit: number = 100;
    max_withdrawal_limit: number = 100;
    mobile: string;
    wallet_balance: number = 0;
    promo_balance: number = 0;
    min_deposit_limit: number = 100;
    min_withdrawal_limit: number = 100;
}