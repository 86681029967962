import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
    selector: 'boot-pagination',
    templateUrl: './boot-pagination.component.html',
    styleUrls: ['./boot-pagination.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BootPaginationComponent implements OnInit, OnChanges {
    @Input() collectionSize?: number;
    @Input() page?: number = 1;
    @Output() pageChange?= new EventEmitter();
    @Input() pageSize?: number;
    @Output() pageSizeChange?= new EventEmitter();
    constructor(public GS: GlobalService) { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.pageSize) {
            this.pageSize = changes.pageSize.currentValue;
        }
        if (changes.collectionSize) {
            this.collectionSize = changes.collectionSize.currentValue;
        }
        if (changes.page) {
            this.page = changes.page.currentValue;
        }
    }

    paginate($event) {
        let total_pages = Math.ceil(this.collectionSize / this.pageSize);
        let hit_page = Math.ceil($event)
        if (hit_page <= 0 || hit_page > total_pages || hit_page == this.page)
            return;
        this.page = hit_page;
        this.pageChange.emit(this.page);
    }

    sizeChange($event) {
        this.pageSizeChange.emit(this.pageSize);
    }

}