import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';

@Component({
  selector: 'how-to-play',
  templateUrl: './how-to-play.component.html',
  styleUrls: ['./how-to-play.component.scss']
})
export class HowToPlayComponent implements OnInit {


  public gridData = {
    data: [],
    per_page: 1,
    current_page: 1,
    total: 0,
  };
  public gridConfig = {
    column: {},
  };

  constructor(public GS: GlobalService) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this.GS.Ajax(this.GS.WIU.listContactUs).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.gridConfig = results.data.config;
      this.gridData = results.data;
    });
  }

}