<div class="row">
  <div class="col-12 text-center">
    <div class="card">
      <span *ngIf="GS.user_detail.name">
        Welcome <b class="text-primary">{{GS.strToUpper(GS.user_detail.name)}}</b>, Wallet Balance :-
        <b class="text-primary">{{GS.user_detail.wallet_balance}}</b>
      </span>
      <div class="row">
        <div *ngIf="GS.user_detail.mobile" class="col-6">
          <button *ngIf="!GS.app_config.IS_DEMO_ENV" class="btn text-primary" (click)="GS.displayReferLink(true)">
            {{GS.PH.refer}}
          </button>
          <button *ngIf="GS.app_config.IS_DEMO_ENV && GS.app_config.IS_DEMO_LIVE" class="btn text-primary"
            (click)="GS.showLiveMessage(true)">
            {{GS.PH.play_with_cash}}
          </button>
        </div>
        <div
          [ngClass]="{'col-6': GS.sessionExist(),'col-12': !GS.sessionExist(), 'text-right' : GS.sessionExist(), 'text-center' : !GS.sessionExist() }">
          <button class="btn text-primary" (click)="GS.getUserDetail()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="GS.sessionExist() && !GS.app_config.enable_spin && !GS.app_config.IS_DEMO_ENV" class="row">
  <div class="col-md-12 text-center">

    <button class="btn btn-danger" (click)="GS.displayReferLink(true)">
      <span>Refer & Earn {{GS.app_config.referral_bonus}} Coins</span>
    </button>
  </div>
</div>
<div *ngIf="GS.sessionExist() && wheel_config.items.length && GS.app_config.enable_spin" class="row">
  <div class="col-md-12 text-center">
    <ngx-wheel #wheel [width]='wheel_config.width' [height]='wheel_config.height'
      [spinDuration]='wheel_config.spinDuration' [disableSpinOnClick]='wheel_config.disableSpinOnClick'
      [items]='wheel_config.items' [innerRadius]='wheel_config.innerRadius' [spinAmount]='wheel_config.spinAmount'
      [textOrientation]='wheel_config.textOrientation' [textAlignment]='wheel_config.textAlignment'
      [pointerStrokeColor]='wheel_config.pointerStrokeColor' [pointerFillColor]='wheel_config.pointerFillColor'
      [idToLandOn]='wheel_config.idToLandOn' (onSpinStart)='before()' (onSpinComplete)='after()'>
    </ngx-wheel>
    <br />
    <button *ngIf="spinTimer > 0" class="btn btn-primary">
      <app-timer [(inputVal)]="spinTimer" (timerOver)="spinTimerStop()"></app-timer>
    </button>
    <button [disabled]="wheel_config.rotating" *ngIf="spinTimer <= 0" class="btn btn-danger" (click)="spin()">
      <span>Spin Now</span>
    </button>
  </div>
</div>

<div class="row">
  <ng-container *ngFor="let game of gameList; let i = index">
    <div class="col-md-6 col-xl-4 text-center"
      *ngIf="(GS.app_config.IS_DEMO_ENV && GS.environment.channel == 'M' && game.label == 'Online Lottery') || !GS.app_config.IS_DEMO_ENV || (GS.app_config.IS_DEMO_ENV && GS.environment.channel != 'M')">
      <a [routerLink]=[game.url]>
        <div class="card">
          <div class="card-body">
            <button class="btn btn-dark" style="width: 100%;">{{game.label}}</button>
            <p class="card-text text-center">{{game.description}}</p>
          </div>
        </div>
      </a>
    </div>
  </ng-container>
</div>

<div class="modal fade" id="wheelResultModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.congratulations}}</h5>
        <button type="button" class="close" (click)="GS.hide('wheelResultModal')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-card [hidHeader]="true" [options]="false">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-12">
                  <h4>{{GS.PH.wheel_win}} {{winner['text']}}</h4>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="GS.hide('wheelResultModal')">{{GS.PH.close}}</button>
      </div>
    </div>
  </div>
</div>