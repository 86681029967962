import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';

@Component({
  selector: 'my-bet-history',
  templateUrl: './my-bet-history.component.html',
  styleUrls: ['./my-bet-history.component.scss']
})
export class MyBetHistoryComponent implements OnInit {

  public gridData = {
    data: [],
    per_page: 1,
    current_page: 1,
    total: 0,
  };
  public gridConfig = {
    column: {},
  };



  public filterFormData = new FilterPoJo();
  constructor(public GS: GlobalService) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this.GS.Ajax(this.GS.WIU.listMyHistory, this.filterFormData).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.gridConfig = results.data.config;
      this.gridData = results.data.data;
    });
  }

  onPageChange(event?) {
    console.log('>>>>onPageChange()<<<<<<<', event);
    this.filterFormData.page = event;
    this.list();
  }

  search(){
    this.filterFormData.page = 1;
    this.list();
  }

}
export class FilterPoJo {
  per_page: number = 10;
  page: number = 1;
}