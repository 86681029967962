import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';

@Component({
  selector: 'download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  constructor(public GS: GlobalService) { }

  ngOnInit() {

  }

}