import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';
import { timer } from 'rxjs';
import { NgxWheelComponent } from 'ngx-wheel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild(NgxWheelComponent, { static: false }) wheel;
  // @ViewChild('referAndEarnComponent') referAndEarnComponent:ReferAndEarnComponent;
  // @ViewChild('referAndEarnComponent', { static: false }) referAndEarnComponent: ReferAndEarnComponent;
  constructor(public GS: GlobalService,
    public route: ActivatedRoute) {
  }

  ngAfterViewInit() {
    // console.log('only after THIS EVENT "wheel" is usable!!');
    // Call the spin function whenever and wherever you want after the AfterViewInit Event
    // this.wheel_test.spin();
  }
  ngOnInit() {
    let request_ad = this.route.snapshot.paramMap.get("request_ad");
    if (request_ad)
      localStorage.setItem(this.GS.CONST.REQUEST_AD, request_ad);
    let register_uid = this.route.snapshot.paramMap.get("register_uid");
    if (register_uid) {
      this.loginWithUID(register_uid);
    }
    this.GS.getAppConfig();
    if (this.GS.sessionExist()) {
      this.getWheelConfig();
    }
    if (this.GS.sessionExist())
      setTimeout(() => {
        if (this.spinTimer > 0) {
          this.GS.displayReferLink();
          this.GS.showLiveMessage();
        }
      }, 5000);
  }

  loginWithUID(register_uid) {
    let data = { register_uid: register_uid };
    this.GS.Ajax(this.GS.WIU.loginWithUID, data).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        window.location.href = this.GS.COMMON_URL;
        return;
      }
      localStorage.removeItem(this.GS.CONST.TOKEN_KEY);
      localStorage.setItem(this.GS.CONST.TOKEN_KEY, results.data.api_token);
      window.location.href = this.GS.COMMON_URL;
    });
  }


  public gameList = [
    {
      label: "Online Lottery",
      icon: "assets/images/online_lottery.png",
      description: "Play & Win 10 Times Coins",
      url: '/bet-dashboard'
    },
    {
      label: "Live Quiz",
      icon: "assets/images/live_quiz.jpeg",
      description: "Play Quiz & Win Coins",
      url: '/live-quiz'
    },
    {
      label: "Sports Play",
      icon: "assets/images/sport_bet.jpg",
      description: "Play On Your Favourite Sports",
      url: '/sport-bet'
    }
  ];

  public wheel_config = {
    items: [],
    width: 280,
    height: 280,
    spinDuration: 8,
    disableSpinOnClick: true,
    innerRadius: 7,
    spinAmount: 10,
    textOrientation: undefined,
    textAlignment: 'center',
    pointerStrokeColor: "red",
    pointerFillColor: "purple",
    idToLandOn: 5,
    spinTimer: 5,
    rotating: false
  };
  public spinTimer: number = this.wheel_config.spinTimer;
  public spinSubscriber: any = '';

  spinTimerStart() {
    let source = timer(0, 1000);
    this.spinSubscriber = source.subscribe(val => {
      this.spinTimer = this.wheel_config.spinTimer - val;
    });
  }

  spinTimerStop() {
    this.spinTimer = 0;
    if (this.spinSubscriber)
      this.spinSubscriber.unsubscribe();
  }

  getWheelConfig() {
    this.wheel_config.rotating = true;
    this.GS.Ajax(this.GS.WIU.getWheelConfig).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.wheel_config = results.data;
      this.spinTimer = this.wheel_config.spinTimer;
      if (this.spinTimer > 0)
        this.spinTimerStart();
    });
  }

  async spin() {
    this.spinTimerStop();
    this.wheel_config.rotating = true;
    this.wheel.reset();
    await new Promise(resolve => setTimeout(resolve, 0)); // Wait here for one tick
    this.wheel.spin();
  }

  before() {
  }

  public winner = {};
  after() {
    for (let i = 0; i < this.wheel_config.items.length; i++) {
      if (this.wheel_config.items[i].id == this.wheel_config.idToLandOn) {
        this.winner = this.wheel_config.items[i];
        break;
      }
    }
    this.GS.Ajax(this.GS.WIU.wheelResultSubmit).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        this.wheel_config.rotating = false;
        this.getWheelConfig();
        return;
      }
      this.GS.show('wheelResultModal');
      this.wheel_config.rotating = false;
      this.getWheelConfig();
      this.GS.getUserDetail();
    });
  }


  public subscribeTimer: Array<number> = [];
  public subscriber: Array<any> = [];

  public liveGrid = [];

  public gridData = this.GS.gridData;
  public gridConfig = this.GS.gridConfig;

  public myData = this.GS.gridData;
  public myConfig = this.GS.gridConfig;

  public formData = new PoJo();
  public filterFormData = new FilterPoJo();
  public myFormData = new FilterPoJo();
  public error = new ERROR();

  ngOnDestroy() {
    if (this.spinSubscriber)
      this.spinSubscriber.unsubscribe();
  }
}
export class FilterPoJo {
  per_page: number = 5;
  page: number = 1;
}

export class PoJo {
  lottery_id: number;
  bet_number: number;
  user_qty: number = 0;
  label: string;
  wallet_balance: number = 50;
  ticket_amount: number;
  css_class: string = '';
}

export class ERROR {
  lottery_id: string;
  bet_number: string;
  user_qty: string;
}
