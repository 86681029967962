export const CONST = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    POST: 'post',
    GET: 'get',
    QUERY: 'query',
    TEMPLATE: 'template',
    TOKEN_KEY: 'master_api_token',
    REQUEST_AD: 'request_ad',
    REFER_KEY: 'refer_key',
    VAL_ERR: 'validation_error',
    SEP: '_',
    ORDER_BY_TYPE: [
        {
            'id': 'ASC',
            'name': 'Ascending'
        },
        {
            'id': 'DESC',
            'name': 'Descending'
        }
    ],
    payment_type: [
        {
            'id': 'upi',
            'name': 'UPI'
        },
        {
            'id': 'paytm',
            'name': 'PayTm'
        }
    ],
    ENTITY_TYPE: {
        banner_gif_hkb: 'banner_gif_hkb',
        apk_file: 'apk_file',
        dmg_file: 'dmg_file',
        apk_qr_code: 'apk_qr_code',
        dmq_qr_code: 'dmg_qr_code',
    },
    NUM_TYPE: {
        'count': 'count',
        'amount': 'amount',
        'coin': 'coin'
    },
    COMPARISION_OP: {
        '<': '<',
        '<=': '<=',
        '=': '=',
        '>': '>',
        '>=': '>=',
    },
}