<app-card [cardTitle]="GS.PH.profile" [options]="false">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-12">
          <mobile-number [disabled]="true" [(inputVal)]="formData.mobile"></mobile-number>
          <small *ngIf="error.mobile" class="text-danger">
            {{error.mobile[0]}}
          </small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <input type="password" class="form-control" [(ngModel)]="formData.current_password"
            [placeholder]="GS.PH.current_password">
          <small *ngIf="error.current_password" class="text-danger">
            {{error.current_password[0]}}
          </small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <input type="password" class="form-control" [(ngModel)]="formData.password" [placeholder]="GS.PH.password">
          <small *ngIf="error.password" class="text-danger">
            {{error.password[0]}}
          </small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <input type="password" class="form-control" [(ngModel)]="formData.confirm_password"
            [placeholder]="GS.PH.confirm_password">
          <small *ngIf="error.confirm_password" class="text-danger">
            {{error.confirm_password[0]}}
          </small>
        </div>
      </div>
      <button type="submit" (click)="updatePassword()" class="btn btn-primary">Submit</button>
    </div>
  </div>
</app-card>