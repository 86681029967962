import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(public GS: GlobalService) { }
  public error = new Error();
  public formData = new PoJo();
  ngOnInit() {
    this.getUserDetail();
  }

  public getUserDetail() {
    this.GS.Ajax(this.GS.WIU.getUserDetail).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.formData = new PoJo();
        return;
      }
      this.formData = results.data;
    });
  }

  public updatePassword() {
    this.GS.Ajax(this.GS.WIU.updatePassword, this.formData).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.error = this.GS.getFormError(results);
        return;
      }
      this.GS.alert('success', results);
      this.GS.navigate('/');
    });
  }

}

export class PoJo {
  mobile: number;
  current_password: string;
  password: string;
  confirm_password: string;
}
export class Error {
  mobile: string;
  current_password: string;
  password: string;
  confirm_password: string;
}