import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: 'feather icon-home',
        classes: 'nav-item',
      },
      {
        id: 'add_wallet_balance',
        title: 'Add Wallet Money',
        type: 'item',
        url: '/transaction/add-wallet-balance',
        icon: 'feather icon-plus',
        classes: 'nav-item',
      },
      {
        id: 'withdraw_wallet_balance',
        title: 'Withdraw Wallet Money',
        type: 'item',
        url: '/transaction/withdraw-wallet-balance',
        icon: 'feather icon-minus',
        classes: 'nav-item',
      },
      {
        id: 'redeem_coin',
        title: 'Redeem Coins',
        type: 'item',
        url: '/transaction/redeem-coin',
        icon: 'feather icon-minus',
        classes: 'nav-item',
      },
      {
        id: 'today_history',
        title: "All Results",
        type: 'item',
        url: '/bet-result-history',
        icon: 'feather icon-file-text',
        classes: 'nav-item',
      },
      {
        id: 'my_bet_history',
        title: "All History",
        type: 'item',
        url: '/my-bet-history',
        icon: 'feather icon-server',
        classes: 'nav-item',
      },
      {
        id: 'inbox',
        title: "INBOX",
        type: 'item',
        url: '/inbox',
        icon: 'feather icon-inbox',
        classes: 'nav-item',
      },
      {
        id: 'my_transaction',
        title: 'Transactions',
        type: 'item',
        url: '/transaction/all-transaction/my-transaction',
        icon: 'feather icon-bookmark',
        classes: 'nav-item',
      },
      {
        id: 'how_to_play',
        title: 'How To Play',
        type: 'item',
        url: '/how-to-play',
        icon: 'feather icon-play',
        classes: 'nav-item',
      },
      {
        id: 'contact_us',
        title: 'Contact Us',
        type: 'item',
        url: '/contact-us',
        icon: 'feather icon-phone',
        classes: 'nav-item',
      },
      {
        id: 'signin',
        title: 'Signin',
        type: 'item',
        url: '/auth/signin',
        icon: 'feather icon-log-in',
        classes: 'nav-item',
      },
      {
        id: 'signup',
        title: 'Register',
        type: 'item',
        url: '/auth/signup',
        icon: 'feather icon-edit',
        classes: 'nav-item',
      },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        url: '/logout',
        icon: 'feather icon-log-out',
        classes: 'nav-item',
      },
      {
        id: 'download_app',
        title: 'Download App',
        type: 'item',
        url: '/download-app',
        icon: 'feather icon-download',
        classes: 'nav-item',
      }
    ]
  },
  // {
  //   id: 'ui-element',
  //   title: 'UI ELEMENT',
  //   type: 'group',
  //   icon: 'icon-ui',
  //   children: [
  //     {
  //       id: 'basic',
  //       title: 'Component',
  //       type: 'collapse',
  //       icon: 'feather icon-box',
  //       children: [
  //         {
  //           id: 'button',
  //           title: 'Button',
  //           type: 'item',
  //           url: '/basic/button'
  //         },
  //         {
  //           id: 'badges',
  //           title: 'Badges',
  //           type: 'item',
  //           url: '/basic/badges'
  //         },
  //         {
  //           id: 'breadcrumb-pagination',
  //           title: 'Breadcrumb & Pagination',
  //           type: 'item',
  //           url: '/basic/breadcrumb-paging'
  //         },
  //         {
  //           id: 'collapse',
  //           title: 'Collapse',
  //           type: 'item',
  //           url: '/basic/collapse'
  //         },
  //         {
  //           id: 'tabs-pills',
  //           title: 'Tabs & Pills',
  //           type: 'item',
  //           url: '/basic/tabs-pills'
  //         },
  //         {
  //           id: 'typography',
  //           title: 'Typography',
  //           type: 'item',
  //           url: '/basic/typography'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'forms',
  //   title: 'Forms & Tables',
  //   type: 'group',
  //   icon: 'icon-group',
  //   children: [
  //     {
  //       id: 'forms-element',
  //       title: 'Form Elements',
  //       type: 'item',
  //       url: '/forms/basic',
  //       classes: 'nav-item',
  //       icon: 'feather icon-file-text'
  //     },
  //     {
  //       id: 'tables',
  //       title: 'Tables',
  //       type: 'item',
  //       url: '/tables/bootstrap',
  //       classes: 'nav-item',
  //       icon: 'feather icon-server'
  //     }
  //   ]
  // },
  // {
  //   id: 'chart-maps',
  //   title: 'Chart & Maps',
  //   type: 'group',
  //   icon: 'icon-charts',
  //   children: [
  //     {
  //       id: 'charts',
  //       title: 'Charts',
  //       type: 'item',
  //       url: '/charts/morris',
  //       classes: 'nav-item',
  //       icon: 'feather icon-pie-chart'
  //     }
  //   ]
  // },
  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'icon-pages',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign up',
  //           type: 'item',
  //           url: '/auth/signup',
  //           target: false,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign in',
  //           type: 'item',
  //           url: '/auth/signin',
  //           target: false,
  //           breadcrumbs: false
  //         }
  //       ]
  //     },
  //     {
  //       id: 'sample-page',
  //       title: 'Sample Page',
  //       type: 'item',
  //       url: '/sample-page',
  //       classes: 'nav-item',
  //       icon: 'feather icon-sidebar'
  //     },
  //     {
  //       id: 'disabled-menu',
  //       title: 'Disabled Menu',
  //       type: 'item',
  //       url: 'javascript:',
  //       classes: 'nav-item disabled',
  //       icon: 'feather icon-power',
  //       external: true
  //     },
  //     {
  //       id: 'buy_now',
  //       title: 'Buy Now',
  //       type: 'item',
  //       icon: 'feather icon-book',
  //       classes: 'nav-item',
  //       url: 'https://codedthemes.com/item/datta-able-angular/',
  //       target: true,
  //       external: true
  //     }
  //   ]
  // }
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
