<div class="row">
  <div class="col-sm-12">
    <app-card [cardTitle]="GS.PH['how_to_play']">
      <ol>
        <li>
          <h6 class="text-primary">Register Yourself</h6>
          <ul>
            <li>Without registration you cannot select number from the lottery.</li>
            <li>So Register your self with us, you just need a mobile number to register with us.</li>
          </ul>
        </li>
        <li>
          <h6 class="text-primary">Login</h6>
          <ul>
            <li>After your registration, Login into the application.</li>
          </ul>
        </li>
        <li *ngIf="GS.app_config.IS_DEMO_ENV">
          <h6 class="text-primary">Redeem Coins</h6>
          <ul>
            <li>You can redeem your winning coins by visiting Redeem Menu or click below.</li>
            <li><a [target]="'_self'" [href]="'#/transaction/redeem-coin'">Click Here To Redeem Your Coins</a></li>
          </ul>
        </li>
        <li *ngIf="GS.app_config.IS_DEMO_ENV">
          <h6 class="text-primary">Get A Wallet Balance</h6>
          <ul>
            <li>Spin The Wheel and Get more Coins.</li>
            <li>Use Coins to play lottery and win more coins, then redeem your coins.</li>
          </ul>
        </li>
        <li *ngIf="!GS.app_config.IS_DEMO_ENV">
          <h6 class="text-primary">Get A Wallet Balance</h6>
          <ul>
            <li><span class="text-info">Once you done a login,</span> you have to add a <b class="text-danger">Wallet
                Balance</b>.
            </li>
            <li>There are two types of <b class="text-danger">Wallet Balance</b>, One is <b
                class="text-danger">Withdrawable Balance</b> which can withdraw back
              to
              your account detail. Second is <b class="text-danger">Playable Balance</b> which can be only used for
              play lottery and other games.</li>
            <li class="text-info">There are two ways of getting a <b class="text-danger">Wallet Balance</b>.</li>
            <li>First way, You can get the wallet balance (<b class="text-danger">Playable Balance</b>) by referring as
              many people as you can.</li>
            <li>
              Second way, You have to make fund transfer to our account details via mentioned method on
              <b class="text-dark">Add Wallet Balance Page</b>.
            </li>
            <li>Once you made a transfer, then you have to make a deposit request of same amount using
              <b class="text-dark">Add Wallet Balance Form</b>.
            </li>
            <li>Then Our operator will verify the transfer, and will accept your deposit request then your balance get
              added into your wallet which will be <b class="text-danger">Withdrawable Balance</b>.</li>
          </ul>
        </li>
        <li>
          <h6 class="text-primary">Select Your Choice</h6>
          <ul>
            <!-- <li *ngIf="GS.app_config.IS_DEMO_ENV">Remember app is totally for fun purpose, coins are non-transferable.
            </li> -->
            <li>Go To <b class="text-dark">Lottery Dashboard</b>.</li>
            <li>You will see list of varieties of lotteries.</li>
            <li>Every lottery has time limit, before closing lottery select your choice number.</li>
            <li>Select your choice number from your choice lottery like 6 number from <b class="text-info">20Rs.
                Lottery</b>
              where cost of each
              ticket is 20.</li>
            <li>Or select your choice lottery like 3 number from <b class="text-info">100Rs. Lottery</b> where cost of
              each
              ticket is 100.</li>
            <li>On selecting your choice number form will open, place the quantity of lottery in the form and submit it.
            </li>
            <li>Selection of number from lottery is done, wait for timer to over and Lottery Result calculate.</li>
          </ul>
        </li>
        <li>
          <h6 class="text-primary">Lottery Result</h6>
          <ul>
            <!-- <li *ngIf="GS.app_config.IS_DEMO_ENV">Remember app is totally for fun purpose, coins are non-transferable.
            </li> -->
            <li>Once the timer is over and Lottery Result will be automatically calculated.</li>
            <li>If your choosen number gets selected you will <b class="text-danger">win 10 times</b> of your spent
              coins.
            </li>
            <li>Below list of lotteries, you will see Today's lottery result.</li>
            <li>Below Today's lottery result, you will see your selection detail and win and loss detail.</li>
            <li>you can check past dated results in <b class="text-dark">All Results</b> menu.</li>
            <li>you can check your past dated your history in <b class="text-dark">All History</b> menu.</li>
            <li>you can check all transaction detail in <b class="text-dark">Transactions</b> menu.</li>
          </ul>
        </li>
        <li *ngIf="!GS.app_config.IS_DEMO_ENV">
          <h6 class="text-primary">Balance Deduction/Addition</h6>
          <ul>
            <li>When you select choice from lottery, your <b class="text-danger">Wallet Balance</b> gets deducted by
              <b class="text-danger">ticket quantity X ticket price</b>.
            </li>
            <li>If your selected choice wins then, your <b class="text-danger">Wallet Balance</b> gets added by
              <b class="text-danger">ticket quantity X ticket price X 10</b>.
            </li>
            <li>When you select a choice, First <b class="text-danger">Playable Balance</b> gets deducted then
              <b class="text-danger">Withdrawable Balance</b> So your <b class="text-danger">Withdrawable Balance</b>
              will be always safe.
            </li>
            <li>If you win the lottery by selecting a choice using <b class="text-danger">Playable Balance</b>, winning
              amount will be added into your <b class="text-danger">Withdrawable Balance</b>.</li>
          </ul>
        </li>
        <li *ngIf="!GS.app_config.IS_DEMO_ENV">
          <h6 class="text-primary">Withdraw Wallet Balance</h6>
          <ul>
            <li>To withdraw balance, your wallet balance must be more than minimum withdrawable balance limit.</li>
            <li>You can place one withdraw request at a time.</li>
            <li>GoTo <b class="text-dark">Withdraw Wallet Money</b> menu and fill the withdraw money form, remember to
              fill your correct
              account detail, so money should not get transferred to wrong account detail.</li>
            <li>Once you placed withdraw request, your wallet balance gets deducted.</li>
            <li>Our operator will review your withdraw request and transfer fund to your account detail and accept your
              withdraw request.</li>
            <li>Once your withdraw request accepted, you can place another withdraw request.</li>
          </ul>
        </li>
        <li>
          <h6 class="text-primary">Contact Us</h6>
          <ul>
            <li>Remember to save our contact us detail in your personal diary or follow us on our social media pages.
            </li>
            <!-- <li>Some times goverment block the application so you will not be able to use the application.</li> -->
            <li>We regularly post our new application details on our social media pages or via contact detail.</li>
            <li>If you have any doubts or query you can ask on our contact us detail info.</li>
          </ul>
        </li>
      </ol>
    </app-card>
  </div>
</div>