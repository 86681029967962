<div class="row">
  <div class="col-md-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5>{{GS.PH.my_bet_history}}
          <button class="btn text-primary" (click)="search()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <!-- <th scope="col">#</th> -->
                <th *ngFor="let col of gridConfig.column | keys" scope="col">
                  <span>
                    {{col.value}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of gridData.data; let i = index">
                <!-- <th scope="row">{{ i+1 + ( (gridData.current_page - 1) * gridData.per_page ) }}</th> -->
                <ng-container *ngFor="let col of gridConfig.column | keys">
                  <td *ngIf="col.key =='win_amount' || col.key =='user_qty'" class="text-center">
                    {{item[col.key]}}
                  </td>
                  <td *ngIf="col.key !='win_amount' && col.key !='user_qty' && col.key !='label'">
                    {{item[col.key]}}
                  </td>
                  <td *ngIf="col.key=='label'">
                    <span style="font-size: 11px;">{{item[col.key]}}</span><br>
                    <span style="font-size: 9px;">{{item['stop_time'] | date: "dd/MM/y"}}</span><br>
                    <span style="font-size: 9px;">{{item['stop_time'] | date: "hh:mm:ss a"}}
                      ({{item['ticket_amount']}})</span>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <boot-pagination (pageChange)="onPageChange($event)" [collectionSize]="gridData.total"
        [(page)]="filterFormData.page" [(pageSize)]="filterFormData.per_page">
      </boot-pagination>
    </div>
  </div>
</div>