export const MSGS = {
    bad_request: "Bad request",
    unauthorized: "Unauthorized",
    forbidden: "Forbidden",
    check_internet: "Check Internet",
    request_not_found: "Request not found",
    method_not_allowed: "Method not found",
    internal_server_error: "Internal server error",
    please_wait: "Please wait",
    are_you_sure: "Are you sure?",
}
