<div class="d-flex justify-content-between p-2">
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" (click)="paginate(1)" href="javascript:;">&lt;&lt;</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="paginate(page - 1)" href="javascript:;">&lt;</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="paginate(page)" href="javascript:;">{{page}}</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="paginate(page + 1)" href="javascript:;">&gt;</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="paginate(collectionSize/pageSize)" href="javascript:;">&gt;&gt;</a>
      </li>
    </ul>
  </nav>
  ({{GS.PH.total}} {{collectionSize}})
</div>