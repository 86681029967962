<div class="row">
  <div class="col-12 text-center">
    <span class="text-info">Put on your favourite team and Win.</span>
    <div class="card">
      <span *ngIf="GS.user_detail.name">
        Welcome <b class="text-primary">{{GS.strToUpper(GS.user_detail.name)}}</b>, Wallet Balance :-
        <b class="text-primary">{{GS.user_detail.wallet_balance}}</b>
      </span>
      <div class="row">
        <div *ngIf="GS.user_detail.mobile" class="col-4">
          <button *ngIf="!GS.app_config.IS_DEMO_ENV" class="btn text-primary" (click)="GS.displayReferLink(true)">
            {{GS.PH.refer}}
          </button>
          <button *ngIf="GS.app_config.IS_DEMO_ENV && GS.app_config.IS_DEMO_LIVE" class="btn text-primary"
            (click)="GS.showLiveMessage(true)">
            {{GS.PH.play_with_cash}}
          </button>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']"><button class="btn btn-dark">Home</button></a>
        </div>
        <div class="col-4 text-right">
          <button class="btn text-primary" (click)="reloadPageData()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-danger">
          {{GS.PH.win_ratio_info}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6 col-xl-6" *ngFor="let lottery of liveGrid; let i = index">
    <div class="card">
      <div class="card-header" style="padding: 2px 18px !important;">
        <div class="row">
          <div class="col-8" style="padding-right: 5px;padding-left: 5px;">
            <h6>
              {{lottery.code}} ({{lottery.ticket_amount}} coins)
            </h6>
          </div>
          <div class="col-4 text-right" style="padding-right: 5px;padding-left: 5px;">
            <app-timer [(inputVal)]="subscribeTimer[i]" (timerOver)="timerOver($event,i)"></app-timer>
          </div>
          <div class="col-12">{{GS.PH.end_at}}: {{lottery.stop_time | date:'y-MM-d hh:mm:ss a'}}</div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 text-center" *ngFor="let single of lottery.data; let j = index">
            <div (click)="getBetNumberDetail(lottery.id,single.bet_number)">
              <img [src]="GS.API+'/'+single.icon" style="width: 50px; height: 50px;" /><br />
              <b>{{single.sub_code}}</b><br />
              {{(my_current_bet[lottery.id] &&
              my_current_bet[lottery.id][single.bet_number])?my_current_bet[lottery.id][single.bet_number]:0}}<br />
              <div style="font-size: 11px;">
                {{GS.PH.current_win_ratio}}
                <display-currency [inputVal]="single.win_ratio"></display-currency>
                {{GS.PH.times}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="GS.sessionExist()" class="row">
  <div class="col-md-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5>{{GS.PH.my_cricket_bet}}
          <button class="btn text-primary" (click)="searchMyBetResult()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5 class="text-center" *ngIf="!myData.data.length">{{GS.PH.no_data_found}}</h5>
          <table *ngIf="myData.data.length" class="table table-striped">
            <thead>
              <tr>
                <th *ngFor="let col of myConfig.column | keys" scope="col">
                  <span>
                    {{col.value}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of myData.data; let i = index">
                <td *ngFor="let col of myConfig.column | keys">
                  <div *ngIf="col.key =='code'">
                    <span style="font-size: 11px;">{{item[col.key]}} ({{item['ticket_amount']}})</span><br>
                    <span style="font-size: 9px;">{{item['stop_time']| date:'y-MM-d'}}</span><br />
                    <span style="font-size: 9px;">{{item['stop_time']| date:'hh:mm:ss a'}}</span>
                  </div>
                  <span *ngIf="col.key !='code' && col.key !='win_number' && col.key !='label'">
                    {{item[col.key]}}
                  </span>
                  <span *ngIf="col.key =='user_bet_number' || col.key =='win_number'">
                    <button class="btn {{item['css_class']}}">{{item[col.key]}}</button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <boot-pagination (pageChange)="onMyPageChange($event)" [collectionSize]="myData.total" [(page)]="myFormData.page"
        [(pageSize)]="myFormData.per_page">
      </boot-pagination>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5>{{GS.PH.previous_result}}
          <button class="btn text-primary" (click)="list()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5 class="text-center" *ngIf="!gridData.data.length">{{GS.PH.no_data_found}}</h5>
          <table *ngIf="gridData.data.length" class="table table-striped">
            <thead>
              <tr>
                <th *ngFor="let col of gridConfig.column | keys" scope="col">
                  <span>
                    {{col.value}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of gridData.data; let i = index">
                <td *ngFor="let col of gridConfig.column | keys">
                  <span *ngIf="col.key !='win_number' && col.key !='stop_time'">
                    {{item[col.key]}}
                  </span>
                  <span *ngIf="col.key =='win_number'">
                    {{item[col.key]}}
                  </span>
                  <span *ngIf="col.key =='stop_time'">
                    {{item[col.key] | date:"y:MM:dd"}}<br />
                    {{item[col.key] | date:"hh:mm:ss a"}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="betModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.cricket_bet}}</h5>
        <button type="button" class="close" (click)="GS.hide('betModal')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-4">
                {{GS.PH.cricket_match}}
              </div>
              <div class="col-8">
                <b>{{formData.label}}</b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.selected_team}}</div>
              <div class="col-8">
                <img [src]="GS.API+'/'+formData.icon" style="width: 20px; height: 20px;" />
                <b class="text-danger">{{formData.sub_label}} ({{formData.sub_code}})</b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.bet_value_per_qty}}</div>
              <div class="col-8">
                <display-currency [inputVal]="formData.ticket_amount"></display-currency> Coins
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.my_balance}}</div>
              <div class="col-4">
                <display-currency [inputVal]="formData.wallet_balance"></display-currency>
              </div>
              <div class="col-4">
                <a *ngIf="!GS.app_config.IS_DEMO_ENV"
                  [routerLink]="['/transaction/add-wallet-balance']">{{GS.PH.add_balance}}</a>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.my_bet}}</div>
              <div class="col-8">
                <button (click)="minusMe()" class="btn btn-dark cntr-minus"><i class="feather icon-minus"></i></button>
                <input type="number" (change)="numberChange()" class="form-control center-input"
                  [(ngModel)]="formData.user_qty" />
                <button (click)="plusMe()" class="btn btn-success cntr-plus"><i class="feather icon-plus"></i></button>
              </div>
            </div>
            <div class="form-group row" *ngIf="error.user_qty">
              <div class="col-4">&nbsp;</div>
              <div class="col-8">
                <small class="text-danger">
                  {{error.user_qty[0]}}
                </small>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.spending}}</div>
              <div class="col-8">
                <display-currency [inputVal]="(formData.ticket_amount * formData.user_qty)">
                </display-currency>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">{{GS.PH.after_balance}}</div>
              <div class="col-8">
                <display-currency [inputVal]="formData.wallet_balance - (formData.ticket_amount * formData.user_qty)">
                </display-currency>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-danger text-right">
                {{GS.PH.win_ratio_warning}}
              </div>
            </div>
            <div class="row" style="color:green">
              <div class="col-8"><b>{{GS.PH.current_win_ratio}}</b></div>
              <div class="col-4">
                <b>
                  <display-currency [inputVal]="(formData.win_ratio)">
                  </display-currency>
                </b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 text-danger text-right">
                {{GS.PH.win_ratio_info}}
              </div>
            </div>
            <div class="form-group row">
              <div class="col-8">{{GS.PH.current_win_chance}}</div>
              <div class="col-4">
                <display-currency [inputVal]="(formData.win_ratio * formData.ticket_amount * formData.user_qty)">
                </display-currency>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="GS.hide('betModal')">{{GS.PH.cancel}}</button>
        <button type="button" (click)="addUpdateBet()" class="btn btn-primary">{{GS.PH.confirm_bet}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="registerInfo" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.login}}</h5>
        <button type="button" class="close" (click)="GS.hide('registerInfo')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-card [hidHeader]="true" [options]="false">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-12">
                  <h4>{{GS.PH.login_first}}</h4>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="GS.hide('registerInfo')">{{GS.PH.close}}</button>
        <button type="button" (click)="GS.navigate('auth/signin')" class="btn btn-primary">{{GS.PH.login}}</button>
      </div>
    </div>
  </div>
</div>