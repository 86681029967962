import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../theme/shared/global.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-cricket-dashboard',
  templateUrl: './cricket-dashboard.component.html',
  styleUrls: ['./cricket-dashboard.component.scss']
})
export class CricketDashboardComponent implements OnInit {

  public subscribeTimer: Array<number> = [];
  public subscriber: Array<any> = [];
  public math = Math;
  public liveGrid = [];

  public gridData = this.GS.gridData;
  public gridConfig = this.GS.gridConfig;

  public myData = this.GS.gridData;
  public myConfig = this.GS.gridConfig;

  public formData = new PoJo();
  public filterFormData = new FilterPoJo();
  public myFormData = new FilterPoJo();
  public error = new ERROR();
  constructor(public GS: GlobalService) { }

  ngOnInit() {
    this.GS.getAppConfig();
    this.reloadPageData();
    if (this.GS.sessionExist() && !this.GS.app_config.IS_DEMO_ENV)
      setTimeout(() => {
        this.GS.displayReferLink();
        this.GS.showLiveMessage();
      }, 5000);
  }

  startTimer() {
    for (let i = 0; i < this.liveGrid.length; i++) {
      this.subscribeTimer[i] = this.liveGrid[i].gt;
      let source = timer(0, 1000);
      this.subscriber[i] = source.subscribe(val => {
        // console.log(val, '-');
        this.subscribeTimer[i] = this.liveGrid[i].gt - val;
      });
    }
  }

  timerOver(event, i) {
    console.log('timerOver', event, i);
    this.subscriber[i].unsubscribe();
    // setTimeout(() => {
    this.reloadPageData();
    // }, 1000);
  }

  reloadPageData() {
    for (let i = 0; i < this.liveGrid.length; i++) {
      if (this.subscriber[i])
        this.subscriber[i].unsubscribe();
    }
    this.GS.getUserDetail();
    this.listCurrentBet();
    this.list();
    if (this.GS.sessionExist()) {
      this.listMyCurrentBet();
      this.listMyTodayBetResult();
    }
  }

  ngOnDestroy() {
    for (let i = 0; i < this.liveGrid.length; i++) {
      this.subscriber[i].unsubscribe();
    }
  }

  public my_current_bet = {};

  listMyCurrentBet() {
    this.GS.Ajax(this.GS.WIU.listMyCurrentBet).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        this.my_current_bet = {};
        return;
      }
      this.my_current_bet = results.data;
    });
  }

  listCurrentBet() {
    this.GS.Ajax(this.GS.WIU.listCurrentCricketBet).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.liveGrid = results.data;
      this.startTimer();
    });
  }

  list() {
    this.GS.Ajax(this.GS.WIU.listRecentCricketResult).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        this.gridData = this.GS.gridData;
        this.gridConfig = this.GS.gridConfig;
        return;
      }
      this.gridConfig = results.data.config;
      this.gridData = results.data;
    });
  }

  onPageChange(event?) {
    console.log('>>>>onPageChange()<<<<<<<', event);
    this.filterFormData.page = event;
    this.list();
  }


  listMyTodayBetResult() {
    this.GS.Ajax(this.GS.WIU.listMyCricketBet, this.myFormData).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        this.myData = this.GS.gridData;
        this.myConfig = this.GS.gridConfig;
        return;
      }
      this.myConfig = results.data.config;
      this.myData = results.data.data;
    });
  }

  searchMyBetResult() {
    console.log('>>>>searchMyBetResult()<<<<<<<');
    this.myFormData.page = 1;
    this.listMyTodayBetResult();
  }

  onMyPageChange(event?) {
    console.log('>>>>onMyPageChange()<<<<<<<', event);
    this.myFormData.page = event;
    this.listMyTodayBetResult();
  }

  getBetNumberDetail(lottery_id, bet_number) {
    this.error = new ERROR();
    if (!this.GS.sessionExist()) {
      this.GS.show('registerInfo');
      return;
    }
    let data = { lottery_id: lottery_id, bet_number: bet_number };
    this.GS.Ajax(this.GS.WIU.getCricketBetNumberDetail, data).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        return;
      }
      this.formData = new PoJo();
      this.formData = results.data;
      this.GS.show('betModal');
    });

  }

  addUpdateBet() {
    this.error = new ERROR();
    this.GS.Ajax(this.GS.WIU.addUpdateBet, this.formData).subscribe((results: any) => {
      if (results.status != this.GS.CONST.SUCCESS) {
        this.GS.alert('danger', results);
        this.error = this.GS.getFormError(results);
        return;
      }
      this.GS.alert('success', results.messages[0] + ' ' + results.data.entity);
      this.reloadPageData();
      this.GS.hide('betModal');
    });
  }

  plusMe() {
    this.error = new ERROR();
    if (((this.formData.user_qty + 1) * this.formData.ticket_amount) > this.formData.wallet_balance) {
      this.GS.alert('danger', this.GS.PH.not_enough_balance);
      return;
    }
    this.formData.user_qty = this.formData.user_qty + 1;
  }
  minusMe() {
    this.error = new ERROR();
    if (this.formData.user_qty == 0) {
      return;
    }
    this.formData.user_qty = this.formData.user_qty - 1;
  }

  numberChange() {
    this.error = new ERROR();
    if ((this.formData.user_qty * this.formData.ticket_amount) > this.formData.wallet_balance) {
      this.GS.alert('danger', this.GS.PH.not_enough_balance);
      this.formData.user_qty = Math.floor(this.formData.wallet_balance / this.formData.ticket_amount);
      return;
    }
  }

}
export class FilterPoJo {
  per_page: number = 5;
  page: number = 1;
}

export class PoJo {
  lottery_id: number;
  bet_number: number;
  user_qty: number = 0;
  label: string;
  wallet_balance: number = 50;
  ticket_amount: number;
  css_class: string = '';
  win_ratio: number = 1;
  icon: string = "assets/images/ties.png";
  sub_code: string = "TIES";
  sub_label: string = "Ties";
}

export class ERROR {
  lottery_id: string;
  bet_number: string;
  user_qty: string;
}
