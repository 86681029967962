import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap, tap } from "rxjs/operators";
import { CONST } from './const';
import { GlobalService } from './global.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token = "secrettoken";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private GS: GlobalService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.tokenPusher(request);
    request = this.requestLogger(request);
    request = this.startLoader(request);

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.stopLoader(event);
          this.responseLogger(event);
        }
      }),
      catchError(err => {
        this.stopLoader(event);
        this.errorLogger(err);
        // const error = err.error.message || err.statusText;
        return throwError(err);
      }));
  }

  private startLoader(data) {
    console.log('this.startLoader', this.GS.loaderCounter);
    let route = data['url'].substring(((this.GS.API + '/').length)).split('/')[0];
    if (route.endsWith('DD')) {
      return data;
    }
    if (this.GS.loaderCounter === 0) {
      document.getElementById('customajaxloader').classList.remove('hide');
    }
    this.GS.loaderCounter = this.GS.loaderCounter + 1;
    return data;
  }

  private requestLogger(data) {
    console.log('>>>>>>>>requestLogger<<<<<<<<<<<');
    console.log('request', data);
    return data;
  }

  private tokenPusher(request) {
    console.log('>>>>>>>>tokenPusher<<<<<<<<<<<', request);
    let body = JSON.parse(request.body);
    body.original_channel = this.GS.original_channel;
    if (localStorage.getItem(CONST.REQUEST_AD)) {
      body.request_ad = localStorage.getItem(CONST.REQUEST_AD);
      localStorage.removeItem(CONST.REQUEST_AD);
    }
    if (!this.token) {
      return request.clone({
        headers: request.headers,
        body: body
      });
    }
    let token = (localStorage.getItem(CONST.TOKEN_KEY)) ? localStorage.getItem(CONST.TOKEN_KEY) : sessionStorage.getItem(CONST.TOKEN_KEY);
    request.headers = request.headers.set('Authorization', "Bearer " + token);
    return request.clone({
      headers: request.headers,
      body: body
    });
    // If you are calling an outside domain then do not add the token.
    // if (!request.url.match()) {
    //   return request;
    // }
  }

  private responseLogger(res) {
    console.log('>>>>>>>>responseLogger<<<<<<<<<<<');
    console.log('response', res);
  }

  private errorLogger(e) {
    console.log('response Error', e);
    console.log(e.status);
    switch (e.status) {
      case 0:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.check_internet);
        // this.GS.navigate('/pages/login');
        break;
      case 400:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.bad_request);
        break;
      case 401:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.unauthorized);
        break;
      case 403:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.forbidden);
        break;
      case 404:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.request_not_found);
        break;
      case 405:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.method_not_allowed);
        break;
      case 500:
        this.GS.alert('danger', e.status + ': ' + this.GS.MSGS.internal_server_error);
        break;
    }
  }

  private stopLoader(res) {
    console.log('this.stopLoader', this.GS.loaderCounter);
    if (this.GS.loaderCounter > 0) {
      this.GS.loaderCounter = this.GS.loaderCounter - 1;
      if (this.GS.loaderCounter == 0) {
        document.getElementById('customajaxloader').classList.add('hide');
      }
    }
  }

  private refreshAccessToken(): Observable<any> {
    return of("secret token");
  }
}
