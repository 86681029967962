import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { HowToPlayComponent } from './how-to-play/how-to-play.component';
import { TodayHistoryComponent } from './today-history/today-history.component';
import { MyBetHistoryComponent } from './my-bet-history/my-bet-history.component';
import { InboxComponent } from './inbox/inbox.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { CricketDashboardComponent } from './cricket-dashboard/cricket-dashboard.component';
import { CricketQuizDashboardComponent } from './cricket-quiz-dashboard/cricket-quiz-dashboard.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard/:request_ad',
        component: HomeComponent
      },
      {
        path: 'auth-dashboard/:register_uid',
        component: HomeComponent
      },
      {
        path: 'dashboard',
        component: HomeComponent
      },
      {
        path: 'sport-bet',
        component: CricketDashboardComponent
      },
      {
        path: 'live-quiz',
        component: CricketQuizDashboardComponent
      },
      {
        path: 'bet-dashboard/:request_ad',
        component: DashboardComponent
      },
      {
        path: 'bet-dashboard',
        component: DashboardComponent
      },
      {
        path: 'bet-result-history',
        component: TodayHistoryComponent,
      },
      {
        path: 'my-bet-history',
        component: MyBetHistoryComponent,
      },
      {
        path: 'inbox',
        component: InboxComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'how-to-play',
        component: HowToPlayComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'download-app',
        component: DownloadAppComponent
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent
      },
      {
        path: 'transaction',
        loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
