import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
    selector: 'mobile-number',
    templateUrl: './mobile-number.component.html',
    styleUrls: ['./mobile-number.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MobileNumberComponent implements OnInit, OnChanges {
    @Input() class?: string = 'form-control';
    @Input() placeholder?: string = 'Mobile Number';
    @Input() disabled?: boolean = false;
    @Input() inputVal?: number;
    @Input() prefix?: string = '+91 ';
    @Output() inputValChange?= new EventEmitter();
    constructor(public GS: GlobalService) { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.inputVal) {
            this.inputVal = changes.inputVal.currentValue;
        }
        if (changes.class) {
            this.class = changes.class.currentValue;
        }
        if (changes.placeholder) {
            this.placeholder = changes.placeholder.currentValue;
        }
    }

    valChange($event?) {
        this.inputValChange.emit(this.inputVal);
    }

}