<style>
  /* css for differentiate between googleapp and realapp */
  .nav-tabs .nav-link {
    background-color: mistyrose !important;
  }

  .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: darkgray !important;
  }

  .pcoded-main-container {
    background-color: slategrey !important;
  }
</style>
<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>

<div class="modal fade" id="show_live_message" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.show_live_message}}</h5>
        <button type="button" class="close" (click)="GS.hide('show_live_message')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 style="padding-bottom: 5px; text-align: center;">{{GS.PH.show_live_message}}</h4>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-12">
                <p [innerHTML]="GS.app_config.live_message"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
          (click)="GS.hide('show_live_message')">{{GS.PH.no_i_want_to_play_on_test_website}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="refer_and_earn" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.refer}}</h5>
        <button type="button" class="close" (click)="GS.hide('refer_and_earn')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 style="padding-bottom: 5px; text-align: center;">{{GS.PH.refer_and_earn}}</h4>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-12">
                <textarea type="text" rows="4" class="form-control" readonly [(ngModel)]="GS.refer_and_earn_link"
                  #userinput>
                  </textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 text-center">
                <button (click)="copyInputMessage(userinput)" class="btn btn-primary"
                  value="click to copy">{{GS.PH.copy_link}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="GS.hide('refer_and_earn')">{{GS.PH.not_now}}</button>
      </div>
    </div>
  </div>
</div>