<div class="row">
  <div class="col-md-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5>{{GS.PH.bet_result}}
          <button class="btn text-primary" (click)="search()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <!-- <th scope="col">#</th> -->
                <th *ngFor="let col of gridConfig.column | keys" scope="col">
                  <span>
                    {{col.value}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of gridData.data; let i = index">
                <!-- <th scope="row">{{ i+1 + ( (gridData.current_page - 1) * gridData.per_page ) }}</th> -->
                <td *ngFor="let col of gridConfig.column | keys">
                  <span *ngIf="col.key !='win' && col.key !='stop_time'">
                    {{item[col.key]}}
                  </span>
                  <span *ngIf="col.key =='stop_time'">
                    {{item['stop_date']}}<br />
                    {{item['stop_time']}}
                  </span>
                  <span *ngIf="col.key =='win' && item['game_type'] == 'lottery'">
                    <button class="btn {{item['css_class']}}">{{item[col.key]}}</button>
                  </span>
                  <span *ngIf="col.key =='win' && item['game_type'] != 'lottery'">
                    {{item[col.key]}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <boot-pagination (pageChange)="onPageChange($event)" [collectionSize]="gridData.total"
        [(page)]="filterFormData.page" [(pageSize)]="filterFormData.per_page">
      </boot-pagination>
    </div>
  </div>
</div>