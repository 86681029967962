import { Component, ViewEncapsulation, OnInit, OnChanges, Input } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
    selector: 'display-currency',
    templateUrl: './display-currency.component.html',
    styleUrls: ['./display-currency.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DisplayCurrencyComponent implements OnInit, OnChanges {
    @Input() inputVal?: number;
    @Input() currencyCode?: string = 'IDR';
    @Input() type?: string = 'amount';
    constructor(public GS: GlobalService) { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.value) {
            this.inputVal = changes.value.currentValue;
        }
        if (changes.currencyCode) {
            this.currencyCode = changes.currencyCode.currentValue;
        }
        if (changes.type) {
            this.type = changes.type.currentValue;
        }
    }

}