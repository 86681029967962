<header>
  <div class="m-header" [style.display]="this.headerStyle">
    <a class="mobile-menu" id="mobile-collapse1" href="javascript:"
      (click)="this.onNavCollapsedMob.emit();"><span></span></a>
    <a [routerLink]="['/']" class="b-brand">
      <img height="50" width="50" src="assets/images/lucky91world-logo.png" class="img-radius" alt="Lucky 91 World">
      <span class="b-title">{{GS.PH.app_name}}</span>
    </a>
  </div>
  <a class="mobile-menu" [ngClass]="{'on' : this.menuClass, 'three_dot': GS.notifications.length && !this.menuClass}"
    id="mobile-header" href="javascript:" (click)="toggleMobOption()">
    <i class="feather icon-more-horizontal"></i>
  </a>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</header>