import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from './theme/shared/global.service';
import { MainInterceptor } from './theme/shared/main-interceptor.service';
@Component({
  selector: 'app-root',
  providers: [GlobalService, MainInterceptor],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'datta-able';

  constructor(public router: Router,
    public GS: GlobalService) { }

  ngOnInit() {
    this.GS.getAppConfig();
    this.GS.getUserDetail();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.GS.alert('success', this.GS.PH.copied);
  }

}
