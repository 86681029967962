import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
    selector: 'app-timer',
    templateUrl: './app-timer.component.html',
    styleUrls: ['./app-timer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppTimerComponent implements OnInit, OnChanges {
    @Input() inputVal?: number = 0;
    @Output() timerOver?= new EventEmitter();
    math = Math;
    public hh = '00';
    public mm = '00';
    public ss = '00';
    constructor(private GS: GlobalService) { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.inputVal) {
            this.inputVal = changes.inputVal.currentValue;
            if (this.inputVal == 0) {
                this.timerChange(this.inputVal);
            }
            this.hh = (Math.floor(this.inputVal / 60 / 60) <= 9) ? '0' + Math.floor(this.inputVal / 60 / 60) : Math.floor(this.inputVal / 60 / 60).toString();
            this.mm = (Math.floor((this.inputVal / 60) % 60) <= 9) ? '0' + Math.floor((this.inputVal / 60) % 60) : Math.floor((this.inputVal / 60) % 60).toString();
            this.ss = (Math.floor(this.inputVal % 60) <= 9) ? '0' + Math.floor(this.inputVal % 60) : Math.floor(this.inputVal % 60).toString();
        }
    }

    timerChange($event?) {
        this.timerOver.emit($event);
    }

}