<ul class="navbar-nav" *ngIf="GS.accessibleMenu['profile']">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i>
        <div *ngIf="GS.notifications.length" class="notification_icon">
          <span class="badge">{{GS.notifications.length}}</span>
        </div>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications
          </h6>
        </div>
        <ul class="noti-body">
          <li class="notification" *ngIf="!GS.notifications.length">
            <div class="media">
              <div class="media-body">
                <p>
                  {{GS.PH['no_new_notification']}}
                </p>
              </div>
            </div>
          </li>
          <li class="notification" *ngFor="let item of GS.notifications">
            <div class="media">
              <div class="media-body">
                <p>
                  <a class="notification-toggle" [routerLink]="['/inbox']" ngbDropdownToggle
                    data-toggle="dropdown">{{item.subject}}</a>
                  <span class="n-time text-muted">
                    <i class="icon feather icon-clock m-r-10"></i>{{item.created_at}}
                  </span>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a class="notification-toggle" [routerLink]="['/inbox']" ngbDropdownToggle data-toggle="dropdown">show all</a>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement='auto'>
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar-2.jpg" class="img-radius" alt="User-Profile-Image">
          <span>{{GS.user_detail['name']}}</span>
          <a href="javascript:" (click)="GS.logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li>
            <a [routerLink]="['/profile']" class="dropdown-item"><i class="feather icon-user"></i> Profile</a>
          </li>
          <li>
            <a [routerLink]="['/inbox']" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a>
          </li>
          <li>
            <a href="javascript:" (click)="GS.logout()" class="dropdown-item"><i class="feather icon-lock"></i>
              {{GS.PH.logout}}</a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>