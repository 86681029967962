<div class="row">
  <div class="col-md-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5>{{GS.PH.inbox}}
          <button class="btn" (click)="search()">
            <i class="feather icon-refresh-ccw"></i>
          </button>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th style="background-color: coral" scope="col">#</th>
                <th style="background-color: coral" *ngFor="let col of gridConfig.column | keys" scope="col">
                  <span>
                    {{col.value}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of gridData.data; let i = index">
                <th scope="row">{{ i+1 + ( (gridData.current_page - 1) * gridData.per_page ) }}</th>
                <td *ngFor="let col of gridConfig.column | keys" (click)="getInbox(item['uid'])">
                  <span *ngIf="item['read_status']">
                    <span *ngIf="col.key == 'subject'" class="text-primary">
                      {{item[col.key]}}
                    </span>
                    <span *ngIf="col.key != 'subject'">
                      {{item[col.key]}}
                    </span>
                  </span>
                  <b *ngIf="!item['read_status']">
                    <span *ngIf="col.key == 'subject'" class="text-primary">
                      {{item[col.key]}}
                    </span>
                    <span *ngIf="col.key != 'subject'">
                      {{item[col.key]}}
                    </span>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <boot-pagination (pageChange)="onPageChange($event)" [collectionSize]="gridData.total"
        [(page)]="filterFormData.page" [(pageSize)]="filterFormData.per_page">
      </boot-pagination>
    </div>
  </div>
</div>


<div class="modal fade" id="message" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{GS.PH.message}}</h5>
        <button type="button" class="close" (click)="getMessageClose()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-card [hidHeader]="true" [options]="false">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-4">
                  {{GS.PH.subject}}
                </div>
                <div class="col-8">
                  {{formData.subject}}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-4">
                  {{GS.PH.date}}
                </div>
                <div class="col-8">
                  {{formData.created_at}}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-4">{{GS.PH.message}}</div>
                <div class="col-8">
                  <p [innerHTML]="formData.body"></p>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="getMessageClose()">{{GS.PH.close}}</button>
      </div>
    </div>
  </div>
</div>