<input type="email" style="position: absolute; z-index: -1; top: 20px; width: 0; height:0" />
<input type="password" style="position: absolute; z-index: -1; top: 20px; width: 0; height:0" />
<app-navigation class="pcoded-navbar"
  [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.dattaConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
  (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapsedMob)="navMobClick()">
</app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-configuration></app-configuration>