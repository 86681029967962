export const PLACEHOLDER = {
    four_not_four: "404",
    page_not_found: "Page Not Found",
    no_data_found: "No Data Found",
    setting: "Settings",
    profile: "Profile",
    my_message: "My Messages",
    logout: "Logout",
    otp: "OTP",
    resend: "Resend",
    verify: "Verify",
    back: "Back",
    email_optional: "Email (optional)",
    full_name: "Full Name",
    login: "Login",
    go_to_login: "Go To Login",
    dashboard: "Dashboard",
    change_password: "Change Password",
    current_password: "Current Password",
    old_password: "Old Password",
    new_password: "New Password",
    verify_password: "Verify New Password",
    password: "Password",
    confirm_password: "Confirm Password",
    email: "Email",
    search: "Search",
    add: "Add",
    register: "Register",
    country_isd: "Country ISD",
    your_mobile_number: "Your Mobile Number",
    referral_mobile_optional: "Referral User Mobile (Optional)",
    paytm_mobile_no: "PayTm Registered Mobile No",
    message: "Message",
    alert: "Alert",
    mobile_no_mask: "0000-00-0000",
    accept_t_and_c: "Accept Terms & Conditions",
    already_have_account: "Already have an account?",
    forgot_password: "Forgot password?",
    reset: "Reset",
    dont_have_account: "Don’t have an account?",
    user_from_world: "Users From World",
    app_name: "Lucky 91 World",
    total: "Total",
    per_page: "Per Page",
    today_history: "Today's Lottery History",
    my_history: "My History",
    bet: "Lottery",
    cancel: "Cancel",
    confirm_bet: "Confirm Submit",
    lottery_label: "Lottery",
    lottery_value: "Coins",
    per_bet_value: "Per Lottery Coins",
    my_balance: "My Balance",
    my_bet: "Buy Qty",
    after_balance: "After Balance",
    winning_chance_amount: "Win Amt",
    not_enough_balance: "Not enough balance!",
    bet_number: "Lottery No.",
    end_at: "End At",
    add_wallet_balance: "Add Wallet Balance",
    transaction_ref_no: "Transaction ref no",
    amount: "Amount",
    submit: "Submit",
    register_me: "Register Me",
    add_balance: "Add Balance",
    follow_us_on_below_page: "Dear Users always follow us on below pages",
    how_to_play: "How To Play",
    register_first: "Dear user, before choosing you need to register first!",
    login_first: "Dear user, before placing a choosing you need to Login first!",
    account_info: "Account Information",
    min_deposit_limit: "Minimum Deposit Limit",
    max_deposit_limit: "Maximum Deposit Limit",
    upi: "UPI",
    paytm: "PayTm",
    crypto: "BitCoin",
    paytm_link: "PayTm Link",
    paytm_no: "PayTm No.",
    click_link: "Click Link",
    withdraw_info: "Withdraw Information",
    min_withdrawal_limit: "Minimum Withdrawal Limit",
    max_withdrawal_limit: "Maximum Withdrawal Limit",
    withdraw_wallet_balance: "Withdraw Wallet Balance",
    user_withdraw_account: "Your UPI ID/PayTm Number",
    pending_transaction_request: "Your transaction request is already pending. Please wait till it get verified and approved.",
    pending_withdrawal_request: "Your withdrawal request is pending. Please wait till our operator make a transfer to your account.",
    paytm_your_no: "Your PayTm No.",
    upi_your_no: "Your UPI No.",
    crypto_your_no: "Your BitCoin No.",
    cannot_be_greater_than_withdrawable: "Amount cannot be greater than withdrawable balance",
    transfer_request_to: "Transfer Request To",
    status: "Status",
    wallet_balance: "Main Balance",
    promo_balance: "Promo Balance",
    withdrawable_balance: "WD Balance",
    my_bet_history: "My Play History",
    inbox: "INBOX",
    my_transaction_history: "My Transaction History",
    contact_us: "Contact Us",
    lastest_bet_result: "Latest Lottery Result",
    my_bet_result: "My Selection",
    bet_result: "All Results",
    subject: "Subject",
    date: "Date",
    close: "Close",
    withdrawal: "Withdrawal",
    deposit: "Deposit",
    my_transaction: "All",
    reference_no: "Trans Ref.",
    remark: "Remark",
    response_date: "Resp Date",
    no_new_notification: "You have no new notification",
    refer: "More Coins",
    refer_and_earn: "Refer your friends, family, Let them enjoy the game.",
    copied: "Referal Link has been copied, You Can Share It!",
    upi_copied: "UPI ID Copy Done.",
    copy_link: "Copy Referral Link",
    download_app: "How To Download Our App:",
    not_now: "Not Now",
    withdraw_request_process_timing: "Dear user, all withdraw request will be processed every day after 11PM.",
    cricket_bet: "Cricket Option",
    cricket_match: "Match",
    bet_value_per_qty: "Per Qty Value",
    selected_team: "Selected Team",
    current_win_ratio: "Current Win Ratio",
    current_win_chance: "Win Amt Chance",
    previous_result: "Previous Results",
    spending: "Amount",
    my_cricket_bet: "My Choice",
    win_ratio: "Win",
    bet_qty: "Qty",
    times: "Times",
    win_ratio_info: "Win Ratio never be lesser than 1.",
    win_ratio_warning: "Win Ratio changes as more player select.",
    bet_on: "Put On",
    quiz: "Quiz",
    selected_choice: "Your Answer",
    how_to_add: "How To Add",
    wheel_win: "You have win",
    congratulations: "Congratulations",
    no_i_want_to_play_on_test_website: "No I want To Play On Test Website",
    show_live_message: "Play Real Lottery, Win Real Cash",
    play_with_cash: "Cash",
    copy_upi: "Copy",
    reset_password: "Reset Password",
    go_to_reset_password: "Go To Reset Password",
    go_to_register: "Go To Register",
    go_to_dashboard: "Go To Dashboard",
    redeem_coin: "Redeem Coins",
    redeem_request_process_timing: "Dear user, all redeem request will be processed within 24 hours, please keep patience.",
    user_mobile: 'Mobile No. to Recharge/refill',
    cannot_be_greater_than_wallet_balance: "Cannot be greater than wallet balance",
    redeem_info: "Redeem Information",
    min_redeem_limit: "Minimum Redeem Limit",
    pending_redeem_request: "Your Redeem request is pending. Please wait till our operator process it.",
    recharge_amount: "Recharge Amount",
}