export const WIU = {
    bankTypeDD: "bankTypeDD",
    getMenu: "getMenu",
    register: "register",
    getRegisteredUser: "getRegisteredUser",
    getOTP: "getOTP",
    verifyOTP: "verifyOTP",
    login: "login",
    getUserDetail: "getUserDetail",
    logout: "logout",
    updatePassword: "updatePassword",
    isLoggedIn: "isLoggedIn",
    getCaptcha: "getCaptcha",
    listLangMessage: "listLangMessage",
    listCurrentBet: "listCurrentBet",
    getBetNumberDetail: "getBetNumberDetail",
    addUpdateBet: "addUpdateBet",
    listRecentBetResult: "listRecentBetResult",
    listMyTodayBetResult: "listMyTodayBetResult",
    listMyHistory: "listMyHistory",
    listTodayHistory: "listTodayHistory",
    getAppConfig: "getAppConfig",
    listResult: "listResult",
    listNotification: "listNotification",
    listInbox: "listInbox",
    getInbox: "getInbox",
    listTransaction: "listTransaction",
    listDeposit: "listDeposit",
    listWithdrawal: "listWithdrawal",
    listMyCurrentBet: "listMyCurrentBet",
    getPendingDeposit: "getPendingDeposit",
    addUpdateDeposit: "addUpdateDeposit",
    getPendingWithdrawal: "getPendingWithdrawal",
    addUpdateWithdrawal: "addUpdateWithdrawal",
    listContactUs: "listContactUs",
    listCurrentCricketBet: "listCurrentCricketBet",
    getCricketBetNumberDetail: "getCricketBetNumberDetail",
    listRecentCricketResult: "listRecentCricketResult",
    listMyCricketBet: "listMyCricketBet",
    listCurrentCricketQuizBet: "listCurrentCricketQuizBet",
    getQuizBetNumberDetail: "getQuizBetNumberDetail",
    listRecentQuizResult: "listRecentQuizResult",
    listMyQuizBet: "listMyQuizBet",
    getWheelConfig: "getWheelConfig",
    wheelResultSubmit: "wheelResultSubmit",
    loginWithUID: "loginWithUID",
    getUserByNumber: "getUserByNumber",
    getPendingRedeemRequest: "getPendingRedeemRequest",
    addUpdateRedeemRequest: "addUpdateRedeemRequest",
}
