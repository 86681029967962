<div class="row">
    <div class="col-md-12 col-xl-12">
        <div class="card">
            <div class="card-header">
                <h5>{{GS.PH.download_app}}
                </h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <ol>
                            <li>Open your Android device Settings.</li>
                            <li>Select Apps and Notifications option.</li>
                            <li>Simply enable “Unknown Sources” option.</li>
                            <li>Some Devices have option to download apps from unknown sources, enable it also.</li>
                            <li>Note: If you won’t find this option under the Apps and Notifications tab. Then try
                                finding it in the
                                device <b>Security</b> Section.
                            </li>
                            <li>Once you done above things, you will be able to download or app apk file.</li>
                            <li>Now You can click below button to download our lucky91world.apk app.</li>
                            <li>Once you downloading is done then you can install lucky91world.apk app.</li>
                        </ol>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center" style="height: 100px;">
                        <a class="btn btn-primary" target="_blank"
                            href="https://www.lucky91world.com/api/lucky91world.apk">Click Here To Download
                            App!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>