<app-card [hidHeader]="true" [options]="false">
  <div class="row">
    <div class="col-md-12 col-xl-12">
      <div class="card">
        <div class="card-header">
          <h5>{{GS.PH.contact_us}}
          </h5>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="text-danger">{{GS.PH.follow_us_on_below_page}}</h5>
            <h5 class="text-center" *ngIf="!gridData.data.length">{{GS.PH.no_data_found}}</h5>
            <table *ngIf="gridData.data.length" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th *ngFor="let col of gridConfig.column | keys" scope="col">
                    <span>
                      {{col.value}}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of gridData.data; let i = index">
                  <td *ngFor="let col of gridConfig.column | keys">
                    <span *ngIf="col.key == 'value' && item['link']">
                      <a [href]="item['link']" target="_blank">{{item[col.key]}}</a>
                    </span>
                    <span *ngIf="col.key != 'value' || !item['link']">
                      {{item[col.key]}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card>